<template>
		<a v-if="newTab" ref="link" :href="url" :class="linkClass" target="_blank" rel="noopener" :aria-label="ariaLabel" @mousemove="mouseMove" @mouseleave="mouseLeave">
			{{ text }}
			<span v-if="showCircle" class="circle" :class="circleClass"></span>
		</a>
		<router-link v-else ref="link" :to="url" :class="linkClass" :aria-label="ariaLabel" @mousemove="mouseMove" @mouseleave="mouseLeave">
			{{ text }}
			<span v-if="showCircle" class="circle" :class="circleClass"></span>
		</router-link>
</template>

<script>
export default {
	data() {
		return {
			linkClass: 'magnetic-link p-r',
			circleClass: ''
		}
	},
	props: {
		text: String,
		url: String,
		ariaLabel: String,
		showCircle: Boolean,
		newTab: Boolean
	},
	methods: {
		mouseMove(event) {
			let link = this.$refs.link.href ? this.$refs.link : this.$refs.link.$el;
			this.magnetic(event, 20, link);
		},
		mouseLeave() {
			let link = this.$refs.link.href ? this.$refs.link : this.$refs.link.$el;
			this.magnetMouseLeave(link);
		}
	},
	beforeMount() {
		if (this.showCircle) {
			this.linkClass = 'magnetic-link p-r link-circle';

			if (this.newTab) {
				this.circleClass = 'external';
			}
		}
	}
}
</script>