
import { createApp } from 'vue';
import App from './App.vue';
import router from './router';
import gsap from 'gsap';
import SplitType from 'split-type';
import { createHead } from "@unhead/vue";

// Import global components
import TheNav from '@/components/single-instance/TheNav.vue';
import TheWrapper from '@/components/single-instance/TheWrapper.vue';
import TheFooter from '@/components/single-instance/TheFooter.vue';

// Add SCSS
import '@/assets/scss/index.scss';

const head = createHead();
const app = createApp(App);

app.use(head);

// Create public functions
app.config.globalProperties.splitText = (text, types = 'lines, words, chars', resize = true) => {
	if (!text) {
		return;
	}

	let elTypes = text.dataset.splitText ?? false;
	if (elTypes) {
		types = elTypes;
	}

	let splittedText = SplitType.create(text, {
		types: types,
		tagName: 'span'
	});

	if (types === 'lines, words, chars' && text) {
		text.classList.add('lwc');
	} else if (types === 'lines, words' && text) {
		text.classList.add('lw');
	} else if (types === 'lines, chars' && text) {
		text.classList.add('lc');
	}
	
	if (resize) {
		// Update splittedText on resize for responsiveness text.
		window.addEventListener('resize', () => {
			splittedText.split();
		});
	}
}

app.config.globalProperties.textReveal = (text, wrapper) => {
	let staggerValue = wrapper.dataset.stagger ?? 0.02,
			delayValue = wrapper.dataset.animationDelay == 'true' ? 0 : 0;

	gsap.to(text, .6, {
		y: '0%',
		scale: 1,
		opacity: 1,
		stagger: staggerValue,
		ease: 'power1.out',
		delay: delayValue,
		onComplete() {
			wrapper.classList.add('animation-finished');
		}
	});
}

app.config.globalProperties.isToucheEnabled = () => {
	return ( 'ontouchstart' in window ) ||
					( navigator.maxTouchPoints > 0 ) ||
					( navigator.msMaxTouchPoints > 0 );
};

app.config.globalProperties.magnetMouseLeave = (el) => {
	gsap.to(el, 1.4, { 
		x: 0, 
		y: 0, 
		ease: 'elastic.out(1.2, .7)' 
	});
}

app.config.globalProperties.magnetic = (e, movement, target, targetChild) => {
	let boundingRect = target.getBoundingClientRect(),
			relX = e.x - boundingRect.left,
			relY = e.y - boundingRect.top;

	gsap.to(target, 1.4, {
		x: (relX - boundingRect.width / 2) / boundingRect.width * movement,
		y: (relY - boundingRect.height / 2) / boundingRect.height * movement,
		ease: 'power4.out'
	});

	if (typeof targetChild !== 'undefined') {
		gsap.to(targetChild, 1.4, {
			x: (relX - boundingRect.width / 2) / boundingRect.width * (movement / 4),
			y: (relY - boundingRect.height / 2) / boundingRect.height * (movement / 4),
			ease: 'power4.out'
		});
	}
}	



// Add global components
app.component('the-nav', TheNav);
app.component('the-wrapper', TheWrapper);
app.component('the-footer', TheFooter);

// Mount the app
app.use(router).mount('#app')