<template>
	<footer>
		<div class="container f f-c f--sb">
			<p>
				<link-item text="info@cornehuisman.nl" url="mailto:info@cornehuisman.nl" ariaLabel="Mail naar Corné Huisman" />
			</p>
			<p>
				<link-item text="+31 6 36 08 22 32" url="tel:+31636082232" ariaLabel="Bel naar Corné Huisman" class="dn db-540" />
			</p>
		</div>
	</footer>
</template>

<script>
import linkItem from '../elements/linkItem.vue';

export default {
	components: {
		'link-item': linkItem,
	}
};
</script>