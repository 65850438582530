<template>
	<div ref="cursor" class="cursor p-f">
		<div class="cursor__inner p-r">
			<span class="scroll b">Scroll</span>
			<span class="go-to b">Bekijk</span>
		</div>
	</div>
	
	<div ref="loadingScreen" class="loading-screen p-f f">
		<div class="container">
			<div class="text f f-c f--sb f-fe">
				<p v-html="loadingTitle" class="b m-b--none" style="font-weight:700;"></p>
				<p v-if="!loadingBufferDone" class="b m-b--none" style="font-weight:700;">{{ loadingPercentage }}</p>
			</div>
			<div v-if="!loadingBufferDone" class="loading-screen__line p-r o-h">
				<span ref="loading-line" class="p-a" :style="loadingStyle"></span>
			</div>
		</div>
	</div>

	<the-nav />

	<router-view v-slot="{ Component }"> 
		<transition @enter="onEnter" @leave="onLeave">
			<component :is="Component" :loadingBufferDone="loadingBufferDone" />
		</transition>
	</router-view>
</template>

<script>

import gsap from 'gsap';
import image1 from "./assets/images/cornehuisman.webp";

export default {
	data() {
		return {
			loading: 0,
			loadingPercentage: 0,
			loadingBufferDone: false,
			loadingTitle: 'Front-end <br /> development',
			imagePreloaded: false
		}
	},
	computed: {
		loadingStyle() {
			return '--width:' + this.loadingPercentage + '%';
		},
		currentRouteName() {
			return this.$route.name;
		}
	},
	beforeMount() {
		// Preload largest image, so we dont need the loader twice
		if (!this.imagePreloaded) {
			const image1El = new Image();
			image1El.src = image1;

			this.imagePreloaded = true;
		}
	},
	watch: {
		$route(to) {
			if (this.loadingBufferDone) {
				this.loadingTitle = to.name;
			}
		}
	},
	mounted() {
		let target = { val: 0 },
				loadingScreen = this.$refs.loadingScreen,
				_this = this;

		setTimeout(() => {
			window.dispatchEvent(new Event('resize'));
		}, 100);

		gsap.to(target, {
			duration: 2, 
			val: 100,
			ease: 'power4.inOut',
			onUpdate: () => {
				_this.loadingPercentage = target.val.toFixed(0);
			},
			onComplete: () => {
				gsap.to(loadingScreen, {
					yPercent: -100,
					ease: 'power1.in',
					duration: .5,
					delay: .2,
					onComplete: () => {
						_this.loadingBufferDone = true;
						loadingScreen.classList.add('z-999');
						gsap.to(loadingScreen, {
							yPercent: 100,
							ease: 'none',
							duration: 0,
						});
					}
				});
			}
		})

		if (!this.isToucheEnabled()) {
			this.cursor();
		}
	},
	methods: {
		onEnter(el, done) {
			if (this.loadingBufferDone) {
				gsap.to(this.$refs.loadingScreen, {
					yPercent: -100,
					ease: 'power1.in',
					duration: .4,
					delay: .6,
					onComplete: () => {
						gsap.to(this.$refs.loadingScreen, {
							yPercent: 100,
							ease: 'none',
							duration: 0,
						});
						done();
					}
				});
			}
		},
		onLeave(el, done) {
			gsap.to(this.$refs.loadingScreen, {
				yPercent: 0,
				ease: 'power1.out',
				duration: .4,
				onComplete: () => {
					document.body.scrollTop = 0; // For Safari
					document.documentElement.scrollTop = 0; // For Chrome, Firefox, IE and Opera
					done();
				}
			});
		},
		cursor() {
			const cursor = this.$refs.cursor;
			let externalLink = false,
					scrollClass = false,
					goToClass = false;

			window.addEventListener("mousemove", (e) => {
				let x = e.clientX - 6,
						y = e.clientY - 12;

				if (externalLink) {
					cursor.classList.remove('external');
				} else if (scrollClass) {
					cursor.classList.remove('active', 'scroll');
				} else if (goToClass) {
					cursor.classList.remove('active', 'go-to');
				}

				externalLink = false,
				scrollClass = false,
				goToClass = false;

				if (e.target.classList.contains('link-circle')) {
					let circle = e.target.querySelector('.circle'),
							circlePos = circle.getBoundingClientRect(),
							circleTop = circlePos.top,
							circleLeft = circlePos.left,
							external = circle.classList.contains('external');

					if (external) {
						externalLink = true
						cursor.classList.add('external');
					}

					gsap.to(cursor, .3, {
						x: circleLeft - 1,
						y: circleTop - 1,
						scale: .5
					});
				} else if (e.target.closest('.circle-item')) {
					goToClass = true;
					cursor.classList.add('active', 'go-to');

					gsap.to(cursor, .3, {
						x: x,
						y: y,
						scale: 1
					});
				} else if (e.target.closest('.cursor-none')) {
					gsap.to(cursor, .3, {
						x: x,
						y: y,
						scale: 0
					});
				} else if (e.target.closest('.header')) {
					scrollClass = true;
					cursor.classList.add('active', 'scroll');

					gsap.to(cursor, .3, {
						x: x,
						y: y,
						scale: 1
					});
				} else {
					gsap.to(cursor, .3, {
						x: x,
						y: y,
						scale: 1
					});
				}
			});
		},
	},
}
</script>