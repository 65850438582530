<template>
	<div id="wrapper">
		<div id="scroller" data-scroll-container>
			<slot></slot>
		</div>
	</div>
</template>

<script>
import locomotiveScroll from "locomotive-scroll";
import gsap from "gsap";
import ScrollTrigger from 'gsap/ScrollTrigger';
gsap.registerPlugin(ScrollTrigger);

export default {
	data() {
		return {
			delay: {
				wrapper: 2.2,
				scroller: 2100
			}
		}
	},	
	props: {
		loadingBufferDone: Boolean
	},
	methods: {
		locoScroll() {
			let multiplierVal = .5;

			// Scrollbar is duplicated when change page, so remove the old one.
			let scrollbar = document.querySelector('.c-scrollbar');
			if (scrollbar) {
				scrollbar.remove();
			}

			if (navigator.userAgent.indexOf('Firefox') > -1) {
				multiplierVal = 1.1;
			} else if (navigator.userAgent.indexOf('Chrome') > -1) {
				multiplierVal = .55
			} else if (navigator.userAgent.indexOf('Safari') > -1) {
				multiplierVal = 1.1
			}

			setTimeout(() => {
				const scroll = new locomotiveScroll({
					el: document.querySelector('[data-scroll-container]'),
					smooth: true,
					multiplier: multiplierVal,
				});
				
				scroll.on('call', (event, enter, object) =>  {
					if (event === 'textReveal' && enter === 'enter') {
						let letters = '',
								wrapper = object.el,
								chars = wrapper.querySelectorAll('.char'),
								words = wrapper.querySelectorAll('.word'),
								delay = wrapper.dataset.delay;

						if (wrapper.classList.contains('lw')) {
							letters = words;
						} else {
							letters = chars;
						}

						if (typeof delay !== 'undefined') {
							setTimeout(() => {
								this.textReveal(letters, wrapper);
							}, delay);
						} else {
							this.textReveal(letters, wrapper);
						}
					} else if (event === 'lazyload-img' && enter === 'enter') {
						const images = object.el.querySelectorAll('img');

						images.forEach(image => {
							let imageSrc = image.dataset.src;
							image.src = imageSrc;
						});
					}
				});
			
				if (window.innerWidth > 1024) {
					scroll.on("scroll", () => {
						ScrollTrigger.update();
					});

					ScrollTrigger.scrollerProxy("#scroller", {
						scrollTop(value) {
							return arguments.length ? scroll.scrollTo(value, { duration: 0, disableLerp: true }) : scroll.scroll.instance.scroll.y;
						},
						getBoundingClientRect() {
							return { top: 0, left: 0, width: window.innerWidth, height: window.innerHeight };
						},
						pinType: document.querySelector("#scroller").style.transform ? "transform" : "fixed"
					});

					ScrollTrigger.addEventListener("refresh", () => scroll.update());
					ScrollTrigger.defaults({ scroller: "#scroller" });

					const circleSpin = document.querySelector('.circle-spin');
					if (circleSpin) {
							const circleSpinner = document.querySelector('.circle-spin__spinner'),
										circleBorder = document.querySelector('.circle-spin__border');

						let navH = document.querySelector('.nav').offsetHeight,
								windowH = window.innerHeight,
								blockH = document.querySelector('.circle-item').offsetHeight,
								wrapperTransform = 100,
								startOffset = ((windowH - navH - blockH) / 2) + navH  + wrapperTransform;

						gsap.set(circleBorder, {
							width: `calc(100% - ${blockH}px)`,
							height: `calc(100% - ${blockH}px)`
						});

						gsap.to(circleSpinner, {
							scrollTrigger: {
								trigger: circleSpin,
								start: `-${startOffset} top`,
								end: `+=${windowH * 2}`,
								scrub: true,
								pin: true,
								markers: false,
							},
							rotation: -48,
							ease: "none"
						});
					}
				}
			}, this.delay.scroller);
		},
	},
	beforeMount() {
		if (this.loadingBufferDone) {
			this.delay = {
				wrapper: .7,
				scroller: 600
			}
		}
	},
	mounted() {
		const _this = this;
		this.locoScroll();

		gsap.to('#wrapper', 1.6, {
			y: '0',
			ease: 'power2.out',
			delay: _this.delay.wrapper
		});
	}
}
</script>