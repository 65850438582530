<template>
  <nav class="nav p-f o-h" :class="{ active: mobileMenuIsActive }">
		<div class="container f fw f-c f--sb p-r">
			<router-link to="/" aria-label="Navigeer naar de homepagina" class="logo-item lh-0 b">
				ch
			</router-link>
			<ul class="nav__items nav__items--internal">
				<li>
					<link-item text="Home" url="/" :showCircle="true" ariaLabel="Navigeer naar de homepagina" />
				</li>
				<li>
					<link-item text="Contact" url="/contact" :showCircle="true" aria-label="Navigeer naar de contactpagina" />
				</li>
			</ul>
			<ul class="nav__items nav__items--external">
				<li>
					<link-item text="LinkedIn" url="https://www.linkedin.com/in/cornehuisman/" :showCircle="true" :newTab="true" aria-label="Navigeer naar mijn LinkedIn pagina" />
				</li>
			</ul>
			<div @click="toggleMenu()" class="hamburger p-r" :class="{ close: mobileMenuIsActive }">
				<div></div>
				<div></div>
				<div></div>
			</div>
			<div class="nav__mobile f">
				<router-link to="/" class="nav__mobile__item p-r f f-c" aria-label="Navigeer naar de homepagina">Home<span class="circle"></span></router-link>
				<router-link to="/contact" class="nav__mobile__item p-r f f-c" aria-label="Navigeer naar de contactpagina">Contact<span class="circle"></span></router-link>
				<hr class="nav__mobile__item">
				<a class="nav__mobile__item" href="https://www.linkedin.com/in/cornehuisman/" aria-label="Navigeer naar mijn LinkedIn account" rel="noopener" target="_blank">LinkedIn</a>
			</div>
		</div>
  </nav>
</template>

<script>
import linkItem from '../elements/linkItem.vue';

export default {
	components: {
		'link-item': linkItem,
	},
	data() {
		return {
			mobileMenuIsActive: false
		}
	},
	methods: {
		toggleMenu(value) {
			this.mobileMenuIsActive = value ?? !this.mobileMenuIsActive;
		},
	},
	mounted() {
		const links = this.$el.querySelectorAll('a');
		links.forEach(link => {
			link.addEventListener('click', () => {
				setTimeout(() => {
					this.toggleMenu(false);
				}, 300);
			})
		});
	}
};
</script>