// import locomotiveScroll from "locomotive-scroll";
import {
	createRouter,
	createWebHistory
} from 'vue-router';

const routes = [{
		path: '/',
		name: 'home',
		meta: { title: 'Homepage wow' },
		component: () => import('../views/HomeView.vue')
	},
	{
		path: '/contact',
		name: 'contact',
		meta: { title: '<Contact></Contact> wow' },
		component: () => import('../views/ContactView.vue')
	},
	{
		path: "/:catchAll(.*)",
		redirect: "/"
	},
];

const router = createRouter({
	history: createWebHistory(),
	routes,
	linkActiveClass: 'active',
	linkExactActiveClass: 'exact-active',
});

export default router